@import url("../../root.css");

/* Work Section */
.work-section {
  position: relative;
  top: 20vh;
}

@media (max-width: 800px) {
  .work-section {
    top: 15vh;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .work-section {
    top: 9vh;
  }
}

@media (max-width: 500px) {
  .work-section {
    width: 75%;
  }
}

/* Work Grid Styling */
.work-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--spacing-medium);
}

@media (max-width: 800px) {
  .work-grid {
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-small);
  }
}

@media (max-width: 500px) {
  .work-grid {
    grid-template-columns: 1fr;
  }
}

.work-item {
  border: 1px solid var(--color-border-translucent);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  cursor: var(--cursor-hover);
}

/* Thumbnail Styling */
.work-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(80%);
  opacity: 0.8;
  transition: var(--transition);
}

@media (max-width: 1250px) {
  .work-thumbnail {
    height: 100px;
  }
}

@media (max-width: 800px) {
  .work-thumbnail {
    height: 15vh;
  }
}

@media (max-width: 500px) {
  .work-thumbnail {
    height: 13vh;
  }
}

/* Hover Effect: Zoom Image */
.work-item:hover .work-thumbnail {
  filter: grayscale(0);
  opacity: 1;
}

/* Text Styles inside Work Items */
.work-item h3 {
  padding: var(--spacing-small);
  color: var(--text-color-dark);
  margin: 0;
  transition: var(--transition);
}

@media (max-width: 500px) {
  .work-item h3 {
    display: none;
  }
}

.light-mode .work-item h3 {
  background-color: var(--navbar-bg-light);
}

.dark-mode .work-item h3 {
  background-color: var(--color-border-translucent);
}

.work-item:hover h3 {
  background-color: var(--color-hover-accent);
  color: var(--text-color-light);
}

.work-item p {
  padding: var(--spacing-small);
  font-size: var(--font-size-paragraph);
  margin: 0;
}

@media (max-width: 800px) {
  .work-item p {
    display: none;
  }
}

.work-link {
  text-decoration: none;
  color: inherit;
  cursor: var(--cursor-hover);
}
